.stripePayment input,
.stripePayment .StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 2em;
  border: 2px solid rgba(50, 50, 93, 0.14902);
  border-radius: 0.375rem;
  outline: 0;
}

.stripePayment input::placeholder {
  color: #aab7c4;
}

.stripePayment input:focus,
.stripePayment .StripeElement--focus {
  outline: none;
  border: 2px solid #368e3a;
  border-radius: 0.375rem;
}
